import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { subYears } from 'date-fns';
import { SelectChangeEvent, Grid } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { IProject } from '../../types/project';
import { ICompletionStatus, IWorksheetPurchaserInfo, IMedia, ISigningOfficer } from '../../types/worksheet';
import styled from 'styled-components';
import Toggle from './Toggle';
import PersonalInfo from './PersonalInfo';
import LicenceUpload from './LicenceUpload';
import AddressDetails from './AddressDetails';
import SigningOfficer from './SigningOfficer';
import Identification from './Identification';
import { mdWidth, smWidth } from '../../utils/CommonStyles';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';

const maxFileSize = 5242880;

const PurchaserInfo = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { project, allExpand, subheading, worksheetId, noCheckbox, style, asterisk } = props;
  const [completionStatus, setCompletionStatus] = useState<ICompletionStatus>({
    personalInfo: allExpand ? true : false,
    addressDetails: allExpand ? true : false,
    signingOfficer: allExpand ? true : false,
    identification: allExpand ? true : false,
    realtorInfo: allExpand ? true : false,
  });

  const animationProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 100 },
    delay: 250,
  });

  const handleTextInput = (event: any) => {
    if (event && event.target) {
      props.dispatchPurchasers({
        type: 'TEXT_CHANGE',
        payload: {
          purchaserNumber: props.purchaserDetails.purchaserNumber,
          field: event.target.name ? event.target.name : 'streetAddress',
          value: event.target.value,
        },
      });
    }
  };

  const handleDetails = (city: string, province: string, postalCode: string, country: string, address: string) => {
    props.dispatchPurchasers({
      type: 'STREET_ADDRESS',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        streetAddress: address,
        city: city,
        province: province,
        postalCode: postalCode,
        country: country,
      },
    });
  };

  const handleIdTypeChange = (event: SelectChangeEvent) => {
    props.dispatchPurchasers({
      type: 'ID_TYPE_CHANGE',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        value: event.target.value,
      },
    });
  };

  const handlePurchaserTypeChange = (event: SelectChangeEvent) => {
    props.dispatchPurchasers({
      type: 'PURCHASER_TYPE_CHANGE',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        value: event.target.value,
      },
    });
  };

  const handleDateChange = (value: Date, field: string) => {
    props.dispatchPurchasers({
      type: 'DATE_CHANGE',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        field: field,
        value: value,
      },
    });
  };

  const handleNext = (event: any) => {
    setCompletionStatus({
      ...completionStatus,
      [event.target.name]: true,
    });
  };

  const handleEditId = (file: any, fileReader: FileReader) => {
    props.dispatchPurchasers({
      type: 'ADD_ID_FILE',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        value: {
          name: file.name,
          file: file,
          url: fileReader.result,
        },
      },
    });
  };

  const handleRemoveId = (event: any, index: number) => {
    props.dispatchPurchasers({
      type: 'REMOVE_ID_FILE',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        index: index,
      },
    });
  };

  const handleToggle = (event: any, newAlignment: string) => {
    props.dispatchPurchasers({
      type: 'TOGGLE_CHANGE',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        field: 'corp',
        value: newAlignment,
      },
    });
  };

  const handleSigningOfficer = (index: number) => {
    props.dispatchPurchasers({
      type: 'ADD_SIGNING_OFFICER',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        index: index,
        value: {
          fullName: '',
          dob: subYears(new Date(), 18),
          email: '',
          primaryPhone: '',
          sin: '',
          streetAddress: '',
        },
      },
    });
  };

  const handleEditSigningOfficer = (field: string, index: number, value: string) => {
    props.dispatchPurchasers({
      type: 'EDIT_SIGNING_OFFICER',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        index: index,
        value: value,
        field: field,
      },
    });
  };

  const handleDateSigningOfficer = (field: string, index: number, value: Date) => {
    props.dispatchPurchasers({
      type: 'EDIT_DATE_SIGNING_OFFICER',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        index: index,
        value: value,
        field: field,
      },
    });
  };

  const handleAddPurchaser = () => {
    props.dispatchPurchasers({
      type: 'ADD_NEW_PURCHASER',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber + 1,
        purchaserComplete: false,
        project: project && project._id,
        signingOfficerComplete: [],
        corp: false,
        firstName: '',
        lastName: '',
        email: '',
        identifications: [],
        primaryPhone: '',
        dob: subYears(new Date(), 18),
        occupation: '',
        employer: '',
        streetAddress: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        idType: '',
        idNumber: '',
        idExpiry: new Date(),
        idJurisdiction: 'Ontario',
        sin: '',
        purchaserType: '',
        signingOfficers: [
          {
            fullName: '',
            dob: subYears(new Date(), 18),
            email: '',
            primaryPhone: '',
            sin: '',
            streetAddress: '',
          },
        ],
      },
    });
  };

  const handleAcknowledgement = (event: any) => {
    props.dispatchPurchasers({
      type: 'TOGGLE_ACKNOWLEDGEMENT',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        value: event.target.value,
      },
    });
  };

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    if (file.size > maxFileSize) {
      storeDispatch(showErrorSnackbar('This file is too large'));
      return;
    }
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      handleEditId(file, fileReader);
    };
  };

  const handleText = (name: string, value: string) => {
    props.dispatchPurchasers({
      type: 'TEXT_CHANGE',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        field: name,
        value: value,
      },
    });
  };

  if (props.purchaserDetails.signingOfficers.length === 0 && props.purchaserDetails.corp) {
    props.dispatchPurchasers({
      type: 'ADD_SIGNING_OFFICER',
      payload: {
        purchaserNumber: props.purchaserDetails.purchaserNumber,
        value: {
          fullName: '',
          dob: null,
          email: '',
          primaryPhone: '',
          sin: '',
          streetAddress: '',
        },
      },
    });
  }

  return (
    <div>
      <PurchaserInfoContainer>
        {!props.disabled && (
          <animated.div style={animationProps}>
            <div>
              <ToggleContainer>
                <Toggle
                  alignment={props.purchaserDetails.corp ? 'corporation' : 'personal'}
                  handleToggle={handleToggle}
                  choice1="personal"
                  choice2="corporation"
                />
              </ToggleContainer>
              <div>
                <div style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                  Upload Identification<span style={{ color: 'red' }}>*</span>
                </div>
                <Flex container spacing={2}>
                  <DropzoneContainer item lg={3} md={4} sm={6} xs={12}>
                    <div
                      style={{
                        padding: '20px',
                        border: '1px solid #c4c4c4',
                        height: '100%',
                      }}
                    >
                      <Dropzone
                        onDrop={handleDrop}
                        accept={{
                          'image/jpeg': ['.jpeg', '.jpg'],
                          'image/png': ['.png'],
                          'application/pdf': ['.pdf'],
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div style={{ height: '100%' }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <InsideText>
                              Drag and drop your file
                              <Divider sx={{ margin: '10px 0' }}>or</Divider>
                              <Button disableElevation variant="contained">
                                Browse
                              </Button>
                            </InsideText>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </DropzoneContainer>
                  {props.purchaserDetails.identifications.map((identification: IMedia, index: number) => {
                    return (
                      <LicenceUpload
                        purchaserId={props.purchaserDetails._id}
                        identification={identification}
                        handleEditId={handleEditId}
                        index={index}
                        handleRemoveId={handleRemoveId}
                        key={index}
                      />
                    );
                  })}
                </Flex>
              </div>
            </div>
          </animated.div>
        )}

        <PersonalInfo
          alignment={props.purchaserDetails.corp ? 'corporation' : 'personal'}
          completionStatus={completionStatus}
          handleTextInput={handleTextInput}
          handleDateChange={handleDateChange}
          handleNext={handleNext}
          disabled={props.disabled}
          fields={props.purchaserDetails}
          handleText={handleText}
          subheading={subheading}
          style={style}
          asterisk={asterisk}
        />
        <AddressInfoContainer>
          <AddressDetails
            completionStatus={completionStatus}
            handleTextInput={handleTextInput}
            handleDetails={handleDetails}
            handleNext={handleNext}
            disabled={props.disabled}
            fields={props.purchaserDetails}
            subheading={subheading}
            style={style}
            asterisk={asterisk}
          />
        </AddressInfoContainer>

        {props.purchaserDetails.corp
          ? props.purchaserDetails.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
              return (
                <SigningOfficer
                  completionStatus={completionStatus}
                  signingOfficer={signingOfficer}
                  handleSigningOfficer={handleSigningOfficer}
                  handleEditSigningOfficer={handleEditSigningOfficer}
                  handleDateSigningOfficer={handleDateSigningOfficer}
                  handleNext={handleNext}
                  disabled={props.disabled}
                  fields={props.purchaserDetails}
                  subheading={subheading}
                  index={index}
                  style={style}
                  asterisk={asterisk}
                  dispatchPurchasers={props.dispatchPurchasers}
                />
              );
            })
          : null}
        <Identification
          completionStatus={completionStatus}
          worksheetId={worksheetId}
          handleTextInput={handleTextInput}
          handleDateChange={handleDateChange}
          handleIdTypeChange={handleIdTypeChange}
          handlePurchaserTypeChange={handlePurchaserTypeChange}
          handleAddPurchaser={handleAddPurchaser}
          handleAcknowledgement={handleAcknowledgement}
          fields={props.purchaserDetails}
          purchaserCount={props.purchaserCount}
          purchaserNumber={props.purchaserDetails.purchaserNumber}
          disabled={props.disabled}
          subheading={subheading}
          noCheckbox={noCheckbox}
          handleText={handleText}
          style={style}
          asterisk={asterisk}
        />
      </PurchaserInfoContainer>
    </div>
  );
};

interface ChildProps {
  project?: IProject;
  worksheetId?: string;
  purchaserDetails: IWorksheetPurchaserInfo;
  purchaserCount: number;
  dispatchPurchasers?: any;
  key?: any;
  allExpand?: boolean;
  disabled?: boolean;
  subheading?: string;
  profile?: boolean;
  noCheckbox?: boolean;
  style?: object;
  asterisk?: object;
}

const PurchaserInfoContainer = styled.div`
  margin-top: 20px;
`;

const ToggleContainer = styled.div`
  margin-bottom: 25px;
  @media (max-width: ${mdWidth}) {
    width: 50%;
    margin-right: 0;
  }
  @media (max-width: ${smWidth}) {
    margin-right: 0;
  }
`;

const InsideText = styled.div`
  text-align: center;
  color: #666666;
  padding-top: 10px;
`;

const AddressInfoContainer = styled.div`
  margin-top: 50px;
`;

const DropzoneContainer = styled(Grid)`
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const Flex = styled(Grid)`
  margin-top: 15px;
`;

export default PurchaserInfo;
