import React, { useState } from 'react';
import styled from 'styled-components';
import { IThirdParty } from '../../types/deal';
import { WorksheetTextField, smWidth, DealsPageLine } from '../../utils/CommonStyles';
import { Grid, Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const ThirdPartyVerification = (props: ChildProps) => {
  const { thirdParty } = props;
  const [dateError, setDateError] = useState<string | null>(null);

  return (
    <div>
      <DealsPageLine />
      <h1 style={{ textAlign: 'center' }}>Third Party Verification</h1>
      {thirdParty.length > 0 ? (
        <>
          {thirdParty.map((thirdPartyMember: IThirdParty, i: number) => (
            <ThirdPartyInfoContainer key={i}>
              <Heading item lg={3} md={4} sm={12} xs={12}>
                Personal Information
              </Heading>

              {thirdParty && (
                <Grid item lg={9} md={8} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <WorksheetTextField label="Full Name" required value={thirdPartyMember?.fullName} disabled />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <WorksheetTextField label="Address" required value={thirdPartyMember?.streetAddress} disabled />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={thirdPartyMember?.dob}
                          label="Date of Birth"
                          onChange={() => {}}
                          onError={(reason: any, value: any) => {
                            switch (reason) {
                              case 'invalidDate':
                                setDateError('Invalid Date');
                                break;
                              case 'disablePast':
                                setDateError('Invalid Date');
                                break;
                              case 'disableFuture':
                                setDateError('Third Party cannot born in the future!');
                                break;
                              case 'minDate':
                                setDateError('Invalid Date');
                                break;
                              case 'shouldDisableDate':
                                // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
                                setDateError('Invalid Date');
                                break;
                              default:
                                if (thirdPartyMember?.dob) {
                                  setDateError(null);
                                } else {
                                  setDateError('Date cannot be empty');
                                }
                            }
                          }}
                          sx={{ width: '100%' }}
                          disabled
                        />
                        {dateError ? <DateError>{dateError}</DateError> : null}
                      </LocalizationProvider>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <WorksheetTextField label="Phone" value={thirdPartyMember?.primaryPhone} disabled />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <WorksheetTextField label="Email" value={thirdPartyMember?.email} disabled />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <WorksheetTextField label="Occupation" required value={thirdPartyMember?.occupation} disabled />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <WorksheetTextField label="Corporation Number" value={thirdPartyMember?.corpNumber} disabled />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <WorksheetTextField label="Relationship with Purchaser" required value={thirdPartyMember?.relationship} disabled />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </ThirdPartyInfoContainer>
          ))}
        </>
      ) : (
        <Box sx={{ mb: '350px', textAlign: 'center' }}>There are no third parties for this deal.</Box>
      )}
    </div>
  );
};

const Heading = styled(Grid)`
  font-size: 1.25rem;
  font-weight: 600;
`;

const ThirdPartyInfoContainer = styled.div`
  display: flex;
  margin-top: 50px;

  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

const DateError = styled.p`
  font-size: 0.75rem;
  color: #f54394;
  margin: 3px 14px 0 14px;
`;

interface ChildProps {
  thirdParty: IThirdParty[];
}

export default ThirdPartyVerification;
