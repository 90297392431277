/* Dependency Imports */
import { useState, useContext } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { minutesInHour, secondsInHour, secondsInMinute } from 'date-fns/constants';
import { subHours } from 'date-fns';

/* Project Imports */
// Contexts
import { AppointmentContext } from '../../../context/AppointmentContext';
// Pages
import Main from './Main';
import FirstPage from './FirstPage';
// Misc
import { IGetAppointments, IAppointmentTimeSlot } from '../../../types/appointment';
import { getDoubleDigitNumber, timeZoneDate, isDST } from '../../../utils/Functions';
import LoadingWrapper from '../../common/LoadingWrapper';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import './appointment.css';
import ConfirmSubmission from './ConfirmSubmission';
// import { IProjectAccess } from '../../types/user';

// Pages start at zero since they are index based
const today = new Date();
today.setHours(0, 0, 0, 0);

const BookAppointment = (props: any) => {
  /* Contexts */
  const storeDispatch = useAppDispatch();
  const { scheduleid } = useParams();
  const { project } = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [appointment, dispatchAppointment] = useContext(AppointmentContext);

  /* States */
  const [appointmentTimeSlots, setAppointmentTimeSlots] = useState<IAppointmentTimeSlot[][][][]>([]);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [appointmentCreated, setAppointmentCreated] = useState<boolean>(false);
  const [registrant, setRegistrant] = useState<boolean | null>(null);

  /* Queries */

  const [checkRegistrant] = useLazyQuery(GETREGISTRANT, {
    onCompleted: (data) => {
      setRegistrant(data.getRegistrant);
    },
  });

  const { loading: appointmentLoading } = useQuery<IGetAppointments>(GETAPPOINTMENTS, {
    skip: (project && Object.keys(project).length === 0) || (project && !project._id),
    fetchPolicy: 'network-only',
    variables: {
      project: project._id,
      today: today.toUTCString(),
      type: 'deal',
      cancelled: false,
      scheduleId: scheduleid ? scheduleid : '',
    },
    onCompleted: (data) => {
      setSchedules(data.getSchedules);
      if (!data.getSchedules.length) {
        return;
      }
      let newAppointmentTimeSlots: IAppointmentTimeSlot[][][][] = [];
      let noTimeslots = true;
      data.getSchedules[0].schedules?.forEach((setting) => {
        // Iterate over each day of the week until the end date
        let year = timeZoneDate(setting.date).getFullYear();
        let month = timeZoneDate(setting.date).getMonth();
        let date = timeZoneDate(setting.date).getDate();
        let timeInSeconds = setting.timeStart;

        // Check if current date is removed

        // Iterate over appointments that fit in the timeslot
        while (timeInSeconds + setting.length * secondsInMinute <= setting.timeEnd) {
          const startHour = Math.floor(timeInSeconds / secondsInHour);
          const startMin = Math.floor((timeInSeconds % secondsInHour) / secondsInMinute);
          timeInSeconds += setting.timeBetweenAppointments * secondsInMinute;

          // Check number of already booked appointments
          let numBooked = 0;
          data.appointmentMany?.forEach(
            (appointment) =>
              (numBooked += Number(
                timeZoneDate(appointment.date! ?? '').getTime() === new Date(year, month, date, startHour, startMin).getTime()
              ))
          );

          // Get timeslot info
          const endHour = startHour + Math.floor((startMin + setting.length) / minutesInHour);
          const endMin = (startMin + setting.length) % minutesInHour;
          const timeslot = getDisplayTime(startHour, startMin) + ' - ' + getDisplayTime(endHour, endMin);
          const timeslotInfo = {
            startHour: startHour,
            startMin: startMin,
            numAppointments: setting.numberOfAppointments,
            numAvailable: setting.numberOfAppointments - numBooked,
            timeslot: timeslot,
            dayslot: setting.date,
            length: setting.length,
            access: data.getSchedules[0].access,
          };

          // Initialize arrays if they are undefined
          if (!newAppointmentTimeSlots[year]) {
            newAppointmentTimeSlots[year] = [];
            noTimeslots = false;
          }
          if (!newAppointmentTimeSlots[year][month]) {
            newAppointmentTimeSlots[year][month] = [];
          }
          if (!newAppointmentTimeSlots[year][month][date]) {
            newAppointmentTimeSlots[year][month][date] = [timeslotInfo];
            continue;
          }

          // Add in new timeslot while sorting
          let index = 0;
          for (const timeslot of newAppointmentTimeSlots[year][month][date]) {
            if (startHour <= timeslot.startHour && startMin <= timeslot.startMin && setting.length < timeslot.length) break;
            ++index;
          }
          newAppointmentTimeSlots[year][month][date].splice(index, 0, timeslotInfo);
        }
      });

      if (noTimeslots) {
        setError('There are currently no available appointments for ' + project.name);
        return;
      }
      setAppointmentTimeSlots(newAppointmentTimeSlots);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  /* Mutations */
  const [appointmentCreate, { loading }] = useMutation(CREATEAPPOINTMENT, {
    onCompleted: (data) => {
      setAppointmentCreated(true);
    },
    onError: (error) => {
      if (error.toString() === 'Error: Appointment has already been created') {
        setError(error.toString());
      } else {
        setError('Error Creating Appointment');
      }
    },
  });

  const getDisplayTime = (hour: number, min: number) => {
    let period = Math.floor(hour / 12) % 2 ? 'pm' : 'am';
    let displayHour = ((hour - 1) % 12) + 1;
    return getDoubleDigitNumber(displayHour) + ':' + getDoubleDigitNumber(min) + period;
  };

  const setError = (error: string) => {
    storeDispatch(showErrorSnackbar(error));
  };

  const submitAppointment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let selectedRegistrant: any;
    if (
      appointment.purchaserInfo.firstName &&
      appointment.purchaserInfo.lastName &&
      appointment.purchaserInfo.primaryPhone &&
      appointment.purchaserInfo.email
    ) {
      selectedRegistrant = true;
    } else {
      selectedRegistrant = await checkRegistrant({ variables: { email: appointment.purchaserInfo.email, project: project._id } });
      selectedRegistrant = selectedRegistrant.data.getRegistrant;
    }
    if (selectedRegistrant === false) {
      setRegistrant(false);
      return;
    }
    let filteredQuestions = [];
    for (let i = 0; i < appointment.questions.length; ++i) {
      if (appointment.questions[i]) filteredQuestions.push(appointment.questions[i]);
    }
    let newAppointment = { ...appointment };
    let purchaserInfo = { ...appointment.purchaserInfo };
    if (
      !newAppointment.purchaserInfo.firstName &&
      !newAppointment.purchaserInfo.lastName &&
      !newAppointment.purchaserInfo.primaryPhone &&
      !newAppointment.purchaserInfo.email
    )
      newAppointment.questions = filteredQuestions;
    newAppointment.project = project._id;
    newAppointment.schedule = scheduleid;
    newAppointment.user = user ? user._id : null;
    newAppointment.type = schedules[0].type;
    newAppointment.location = appointment.location ? appointment.location : schedules[0].locations[0];
    newAppointment.purchaserInfo = newAppointment.purchaserInfo.numberOfGuests
      ? { ...newAppointment.purchaserInfo, numberOfGuests: parseInt(purchaserInfo.numberOfGuests, 10) }
      : {
          ...purchaserInfo,
          numberOfGuests: 0,
        };
    let appointmentRealtorType = newAppointment.purchaserInfo.realtorType;
    delete newAppointment.purchaserInfo.realtorType;

    appointmentCreate({ variables: { record: newAppointment, realtorType: appointmentRealtorType } });
  };

  // TODO: Figure out how the user will declare the type of appointment they are booking
  return appointmentLoading || loading ? (
    <LoadingWrapper title="Please Wait a Moment" modal={false} />
  ) : (
    <BookAppointmentWrapper className={'heightDiv'}>
      {schedules.length ? (
        appointmentCreated ? (
          <ConfirmSubmission project={project} schedules={schedules} user={user} />
        ) : (
          <Main
            project={project}
            user={user}
            schedule={schedules[0]}
            scheduleId={scheduleid}
            timeSlots={appointmentTimeSlots}
            submitAppointment={submitAppointment}
            registrant={registrant}
            setRegistrant={setRegistrant}
          />
        )
      ) : (
        <FirstPage project={project} user={user} schedules={schedules} scheduleId={scheduleid} />
      )}
    </BookAppointmentWrapper>
  );
};

/* Types */

/* Styled Components */

const BookAppointmentWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

/* Queries */
const GETAPPOINTMENTS = gql`
  query getAppointments($project: MongoID!, $today: String!, $type: String!, $cancelled: Boolean!, $scheduleId: String!) {
    appointmentMany(filter: { project: $project, dateGreaterThanEqual: $today, cancelled: $cancelled }, limit: 10000) {
      date
    }
    getSchedules(project: $project, type: $type, today: $today, cancelled: $cancelled, scheduleId: $scheduleId) {
      _id
      active
      allowGuests
      maxGuests
      public
      schedules {
        date
        length
        timeBetweenAppointments
        timeStart
        timeEnd
        numberOfAppointments
      }
      questions {
        _id
        name
        question
        type
        subQuestion
        display
        choices {
          choice
          followUp {
            question
            _id
          }
        }
      }
      locations
      type
      access
      mainText
      endText
      emailEndText
      emailMainText
      name
      hideLocation
    }
  }
`;

/* Mutations */
const CREATEAPPOINTMENT = gql`
  mutation appointmentCreateOne($record: CreateOneAppointmentInput!, $realtorType: String) {
    appointmentCreateOne(record: $record, realtorType: $realtorType) {
      appointment {
        _id
      }
    }
  }
`;

const GETREGISTRANT = gql`
  query getRegistrant($email: String!, $project: MongoID!) {
    getRegistrant(email: $email, project: $project)
  }
`;

export default BookAppointment;
