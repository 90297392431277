import { useState } from 'react';
import styled from 'styled-components';
import { subYears } from 'date-fns';
import { useSpring, animated } from 'react-spring';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { smWidth, WorksheetTextField, Heading } from '../../utils/CommonStyles';
import { ICompletionStatus, IWorksheetPurchaserInfo } from '../../types/worksheet';
import { validateEmail, validatePhone, isPersonalInfoFilled, sanitizePhone } from '../../utils/Validations';
import { formatPhoneNumber } from '../../utils/Functions';

const PersonalInfo = (props: ChildProps) => {
  const { alignment, completionStatus, fields, handleTextInput, handleDateChange, handleNext, disabled, handleText, style, asterisk } = props;
  const { firstName, lastName, email, primaryPhone, dob, occupation, employer, corp } = fields;
  const isXS = useMediaQuery('(max-width: 480px)');
  const [dateError, setDateError] = useState<string | null>(null);

  const animationProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 100 },
    delay: 500,
    padding: '10px',
  });

  return (
    <div>
      <PersonalInfoContainer container spacing={2}>
        <Heading item lg={3} md={4} sm={12} xs={12}>
          <animated.div style={animationProps}>Client Information </animated.div>
        </Heading>

        <Grid item lg={9} md={8} sm={12} xs={12}>
          <animated.div style={animationProps}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField
                  id="firstname-textarea"
                  label={alignment === 'personal' ? 'First Name' : 'Corporation Number'}
                  name="firstName"
                  sx={firstName === '' ? style : asterisk}
                  value={firstName}
                  onChange={handleTextInput}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField
                  id="lastname-textarea"
                  label={alignment === 'personal' ? 'Last Name' : 'Corporation Name'}
                  name="lastName"
                  sx={lastName === '' ? style : asterisk}
                  value={lastName}
                  onChange={handleTextInput}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    value={dob}
                    label="Date of Birth"         
                    onChange={(newValue: any) => {
                      handleDateChange(newValue, 'dob');
                    }}
                    onError={(reason: any, value: any) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateError('Invalid Date');
                          break;
                        case 'disablePast':
                          setDateError('Invalid Date');
                          break;
                        case 'disableFuture':
                          setDateError('Purchaser cannot born in the future!');
                          break;
                        case 'maxDate':
                          setDateError('The purchaser is too young to purchase!');
                          break;
                        case 'minDate':
                          setDateError('Invalid Date');
                          break;
                        case 'shouldDisableDate':
                          // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
                          setDateError('Invalid Date');
                          break;
                        default:
                          if (dob) {
                            setDateError(null);
                          } else {
                            setDateError('Date cannot be empty');
                          }
                      }
                    }}
                    sx={{ width: '100%'}}
                    maxDate={!corp ? subYears(new Date(), 18) : new Date()}
                    disabled={disabled}
                  />
                  {dateError ? <DateError>{dateError}</DateError> : null}
                </LocalizationProvider>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField
                  id="email-textarea"
                  label="Email"
                  name="email"
                  type="email"
                  sx={email === '' ? style : asterisk}
                  error={!disabled && !validateEmail(email) && email !== ''}
                  helperText={!disabled && !validateEmail(email) ? 'Please enter a valid email' : ''}
                  value={email}
                  onChange={handleTextInput}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField
                  id="phone-textarea"
                  label="Phone Number (xxx-xxx-xxxx)"
                  name="primaryPhone"
                  sx={primaryPhone === '' ? style : asterisk}
                  error={!disabled && !validatePhone(primaryPhone) && primaryPhone !== ''}
                  helperText={!disabled && !validatePhone(primaryPhone) ? 'Please enter a valid phone number' : ''}
                  value={formatPhoneNumber(primaryPhone)}
                  onChange={(e) => {
                    handleText(e.target.name, sanitizePhone(e.target.value));
                  }}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField
                  id="occupation-textarea"
                  label="Occupation"
                  name="occupation"
                  value={occupation}
                  sx={occupation === '' ? style : asterisk}
                  onChange={handleTextInput}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <WorksheetTextField
                  id="employer-textarea"
                  label="Employer"
                  name="employer"
                  value={employer}
                  sx={employer === '' ? style : asterisk}
                  onChange={handleTextInput}
                  disabled={disabled}
                  required
                />
              </Grid>
            </Grid>
          </animated.div>
          {completionStatus.personalInfo ? (
            !isXS && (
              <animated.div style={animationProps}>
                <Divider sx={{ marginTop: '50px' }} />
              </animated.div>
            )
          ) : (
            <animated.div style={animationProps}>
              <Button
                disableElevation
                disabled={!isPersonalInfoFilled(fields)}
                variant="contained"
                name="personalInfo"
                onClick={handleNext}
                sx={{
                  marginTop: '10px',
                  '@media (max-width: 480px)': {
                    marginLeft: '0',
                  },
                }}
              >
                Next
              </Button>
            </animated.div>
          )}
        </Grid>
      </PersonalInfoContainer>
    </div>
  );
};

interface ChildProps {
  alignment: string;
  completionStatus: ICompletionStatus;
  fields: IWorksheetPurchaserInfo;
  handleTextInput: any;
  handleDateChange: any;
  handleNext: any;
  handleText: (name: string, value: string) => void;
  disabled?: boolean;
  subheading?: string;
  style?: object;
  asterisk?: object;
}

const PersonalInfoContainer = styled(Grid)`
  display: flex;
  margin-top: 50px !important;

  .MuiIconButton-root {
    margin-top: 0 !important;
    border: none !important;
    width: auto !important;
  }

  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

const DateError = styled.p`
  font-size: 0.75rem;
  color: #f54394;
  margin: 3px 14px 0 14px;
`;

export default PersonalInfo;
