import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { subYears } from 'date-fns';
import styled from 'styled-components';
import { Divider, useMediaQuery, Button, Grid, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomDialog from '../common/CustomDialog';
import { WorksheetTextField, smWidth, xsWidth, Heading } from '../../utils/CommonStyles';
import { ICompletionStatus, ISigningOfficer, IWorksheetPurchaserInfo } from '../../types/worksheet';
import { isSigningOfficerFilled } from '../../utils/Validations';
import { sanitizeSIN } from '../../utils/Validations';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { validateEmail, validatePhone, sanitizePhone } from '../../utils/Validations';
import { formatPhoneNumber } from '../../utils/Functions';

const SigningOfficer = (props: ChildProps) => {
  const {
    completionStatus,
    fields,
    handleNext,
    disabled,
    handleSigningOfficer,
    signingOfficer,
    index,
    handleEditSigningOfficer,
    handleDateSigningOfficer,
    style,
    asterisk,
    dispatchPurchasers,
  } = props;

  const { signingOfficers, purchaserNumber } = fields;
  const [dateError, setDateError] = useState<string | null>(null);
  const [selectedSigningOfficer, setSelectedSigningOfficer] = useState<ISigningOfficer | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const isXS = useMediaQuery(`(max-width: ${xsWidth})`);

  const animationProps = useSpring({
    to: { opacity: completionStatus.addressDetails ? 1 : 0, y: completionStatus.addressDetails ? 0 : 100 },
    from: { opacity: 0, y: 100 },
    delay: 250,
  });

  const handleCloseSuccess = (index: number) => {
    if (selectedSigningOfficer) {
      dispatchPurchasers({ type: 'REMOVE_SIGNING_OFFICER', payload: { purchaserNumber: purchaserNumber, index: index } });
    }
    setDialogOpen(false);
  };

  const handleSelectedSigningOfficer = (signingOfficer: ISigningOfficer) => {
    setSelectedSigningOfficer(signingOfficer);
    setDialogOpen(true);
  };

  const InputFields = (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="fullname-textarea"
          label={'Full Name'}
          name="fullName"
          sx={signingOfficer.fullName === '' ? style : asterisk}
          value={signingOfficer.fullName}
          onChange={(e) => {
            handleEditSigningOfficer(e.target.name, index, e.target.value);
          }}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="street-address"
          label={'Street Address'}
          name="streetAddress"
          sx={signingOfficer.streetAddress === '' ? style : asterisk}
          value={signingOfficer.streetAddress}
          onChange={(e) => {
            handleEditSigningOfficer(e.target.name, index, e.target.value);
          }}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            value={signingOfficer.dob}
            label="Date of Birth"
            defaultCalendarMonth={subYears(new Date(), 18)}
            onChange={(newValue: any) => {
              handleDateSigningOfficer('dob', index, newValue);
            }}
            onError={(reason: any, value: any) => {
              switch (reason) {
                case 'invalidDate':
                  setDateError('Invalid Date');
                  break;
                case 'disablePast':
                  setDateError('Invalid Date');
                  break;
                case 'disableFuture':
                  setDateError('Purchaser cannot born in the future!');
                  break;
                case 'maxDate':
                  setDateError('The purchaser is too young to purchase!');
                  break;
                case 'minDate':
                  setDateError('Invalid Date');
                  break;
                case 'shouldDisableDate':
                  // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
                  setDateError('Invalid Date');
                  break;
                default:
                  if (signingOfficer.dob) {
                    setDateError(null);
                  } else {
                    setDateError('Date cannot be empty');
                  }
              }
            }}
            sx={{ width: '100%'}}
            disabled={disabled}
          />
          {dateError ? <DateError>{dateError}</DateError> : null}
        </LocalizationProvider>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="email-textarea"
          label="Email"
          name="email"
          sx={signingOfficer.email === '' ? style : asterisk}
          type="email"
          error={!disabled && !validateEmail(signingOfficer.email) && signingOfficer.email !== ''}
          helperText={!disabled && !validateEmail(signingOfficer.email) ? 'Please enter a valid email' : ''}
          value={signingOfficer.email}
          onChange={(e) => {
            handleEditSigningOfficer(e.target.name, index, e.target.value);
          }}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="phone-textarea"
          label="Phone Number (xxx-xxx-xxxx)"
          name="primaryPhone"
          sx={signingOfficer.primaryPhone === '' ? style : asterisk}
          error={!disabled && !validatePhone(signingOfficer.primaryPhone) && signingOfficer.primaryPhone !== ''}
          helperText={!disabled && !validatePhone(signingOfficer.primaryPhone) ? 'Please enter a valid phone number' : ''}
          value={formatPhoneNumber(signingOfficer.primaryPhone)}
          onChange={(e) => {
            handleEditSigningOfficer(e.target.name, index, sanitizePhone(e.target.value));
          }}
          disabled={disabled}
          required
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <WorksheetTextField
          id="sinnumber-textarea"
          label="SIN Number (xxx xxx xxx)"
          name="sin"
          sx={signingOfficer.sin === '' ? style : asterisk}
          error={!disabled && signingOfficer.sin.length !== 11 && signingOfficer.sin !== ''}
          helperText={!disabled && signingOfficer.sin.length ? 'Please enter a valid SIN number' : ''}
          onChange={(e) => {
            handleEditSigningOfficer(e.target.name, index, sanitizeSIN(e.target.value));
          }}
          value={signingOfficer.sin}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
 
  return (
    <div style={{ marginTop: '50px' }}>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={() => handleCloseSuccess(index)}
        open={dialogOpen}
        removeButton={'No'}
        successButton={'Yes'}
        dialogContent={`Are you sure?`}
        dialogTitle={'Remove Signing Officer'}
      />
      {completionStatus.addressDetails && (
        <SigningOfficerContainer container spacing={2}>
          <Heading item lg={3} md={4} sm={12} xs={12}>
            <Box sx={{ display: 'flex' }}>
              <animated.div style={animationProps}>Signing Officer {index + 1}</animated.div> 
              {signingOfficers.length > 1 && (
                <IconButton disableRipple size="small" onClick={() => handleSelectedSigningOfficer(signingOfficer)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <animated.div style={{ fontSize: "10px"}}>As per Bylaw 1 and 2 of the Articles of Incorporation</animated.div>
          </Heading>

          <Grid item lg={9} md={8} sm={12} xs={12}>
            <animated.div style={animationProps}>{InputFields}</animated.div>
            {index === signingOfficers.length - 1 ? (
              <div>
                <div style={{ marginTop: '5px' }}>
                  <Button
                    sx={{
                      '@media (max-width: 480px)': {
                        width: '100%',
                        marginBottom: '10px',
                      },
                    }}
                    disableElevation
                    disabled={!isSigningOfficerFilled(signingOfficer)}
                    onClick={() => handleSigningOfficer(index)}
                    variant="contained"
                  >
                    Add Another Signing Officer
                  </Button>
                </div>

                {!completionStatus.signingOfficer && completionStatus.addressDetails && (
                  <animated.div style={animationProps}>
                    <Button
                      disableElevation
                      disabled={!isSigningOfficerFilled(signingOfficer)}
                      variant="contained"
                      name="signingOfficer"
                      onClick={handleNext}
                      sx={{ marginTop: '10px' }}
                    >
                      Next
                    </Button>
                  </animated.div>
                )}
              </div>
            ) : (
              <></>
            )}
          </Grid>
        </SigningOfficerContainer>
      )}
      {!isXS && completionStatus.signingOfficer && <Divider sx={{ marginTop: '50px' }} />}
    </div>
  );
};

interface ChildProps {
  completionStatus: ICompletionStatus;
  signingOfficer: ISigningOfficer;
  fields: IWorksheetPurchaserInfo;
  index: number;
  handleSigningOfficer: any;
  handleNext: any;
  handleEditSigningOfficer: (field: string, index: number, value: string) => void;
  handleDateSigningOfficer: any;
  disabled?: boolean;
  subheading?: string;
  style?: object;
  asterisk?: object;
  dispatchPurchasers: any;
}
const SigningOfficerContainer = styled(Grid)`
  display: flex;
  margin-top: 50px;
  @media (max-width: ${smWidth}) {
    display: block;
  }
`;

const DateError = styled.p`
  font-size: 0.75rem;
  color: #f54394;
  margin: 3px 14px 0 14px;
`;

export default SigningOfficer;
