import { useState, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography, FormControlLabel, FormControl, Button, RadioGroup, Radio, TextField, Grid, Paper, Fade } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { APIProvider, useMapsLibrary, Map, AdvancedMarker } from '@vis.gl/react-google-maps';

import { IProject } from '../../../types/project';
import { IUser } from '../../../types/user';
import { convertAllDates, isDST } from '../../../utils/Functions';
import { validateEmail } from '../../../utils/Validations';
import { addSeconds, subHours } from 'date-fns';
import { AppointmentContext } from '../../../context/AppointmentContext';
import PurchaserForm from './PurchaserForm';
import { FlexEnd } from '../../../utils/CommonStyles';

const Main = (props: ChildProps) => {
  const { schedule, timeSlots, project, user, submitAppointment, registrant, setRegistrant } = props;
  const [appointment, dispatchAppointment] = useContext(AppointmentContext);
  const [date, setDate] = useState<Date | null>(null);
  const [position, setPosition] = useState<IPosition | null>({
    lat: 0,
    lng: 0,
  });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      schedule?.schedules?.length === 1 &&
      schedule?.schedules[0].date &&
      (schedule?.schedules[0].timeEnd - schedule?.schedules[0].timeStart) / schedule?.schedules[0].length === 60
    ) {
      dispatchAppointment({
        type: 'SET_DATE',
        payload: {
          value: new Date(addSeconds(new Date(schedule?.schedules[0].date).setHours(0, 0, 0, 0), schedule?.schedules[0].timeStart)),
        },
      });
    } else if (schedule?.schedules?.length === 1 && schedule?.schedules[0].date) {
      setDate(new Date(new Date(schedule?.schedules[0].date).setHours(0,0,0,0)));
    } else {
      if (schedule?.schedules?.length > 1 && schedule?.schedules[0].date) {
        let closestDate = [...schedule.schedules].sort((a: any, b: any) => {
          let distanceA = Math.abs(new Date().valueOf() - new Date(a.date).valueOf());
          let distanceB = Math.abs(new Date().valueOf() - new Date(b.date).valueOf());
          return distanceA - distanceB;
        });

        setDate(new Date(new Date(closestDate[0].date).setHours(0,0,0,0)));
      }
    }
    if (schedule?.locations.length === 1) {
      dispatchAppointment({ type: 'SET_LOCATION', payload: { value: schedule.locations[0] } });
    }
  }, []);

  function disableDates(date: Date) {
    let allDates = schedule.schedules.map((schedule: any) => new Date(new Date(schedule.date).setHours(0, 0, 0, 0)).valueOf());
    return !allDates.includes(date.valueOf());
  }

  const appointmentsExist = (date: Date) => {
    return (
      timeSlots[date.getFullYear()] &&
      timeSlots[date.getFullYear()][date.getMonth()] &&
      timeSlots[date.getFullYear()][date.getMonth()][date.getDate()]
    );
  };

  const selectTime = (value: string | null) => {
    const dateValue = value ? new Date(value) : null;
    dispatchAppointment({ type: 'SET_DATE', payload: { value: dateValue } });
  };

  function Geocoding() {
    const geocodingLibrary = useMapsLibrary('geocoding');
    if (!geocodingLibrary) return null;
    const geocoder = new geocodingLibrary.Geocoder();

    // ...
    geocoder.geocode({ address: appointment.location }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results?.length) {
          if (position && position.lat !== results[0].geometry.location.lat() && position.lng !== results[0].geometry.location.lng()) {
            setPosition({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            });
          }
          return null;
        } else return null;
      } else return null;
    });
    return null;
  }

  return (
    <Box>
      <Box sx={{ backgroundColor: '#002044' }}>
        <Typography variant="h2" color="#fff" sx={{ textAlign: 'center', p: 2 }}>
          {schedule.name}
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          {schedule.mainText ? (
            <Grid item xs={12}>
              {schedule.mainText}
            </Grid>
          ) : null}
          <form onSubmit={submitAppointment}>
            {schedule?.schedules?.length === 1 &&
            schedule?.schedules[0].date &&
            (schedule?.schedules[0].timeEnd - schedule?.schedules[0].timeStart) / schedule?.schedules[0].length === 60 ? (
              <Box>
                <Box>
                  <strong>Date:</strong> {convertAllDates(new Date(appointment.date), 'PPPPPp')}
                </Box>
              </Box>
            ) : (
              <>
                <Box sx={{ mt: 3, mb: 2 }}>
                  <Typography sx={{ mt: 3 }} variant="h3">
                    <strong>Select Date</strong>
                  </Typography>
                  <Grid container spacing={2} sx={{ mt: 0.2 }}>
                    <Grid item xs={12} sm={4} sx={{ cursor: 'pointer' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date"
                          value={date}
                          open={open}
                          onChange={(newValue) => {
                            dispatchAppointment({ type: 'SET_DATE', payload: { value: null } });
                            setOpen(false);
                            setDate(newValue);
                          }}
                          slotProps={{
                            textField: {
                              onClick: () => setOpen(!open),
                              inputProps: {
                                readOnly: true,
                              },
                            },
                          }}
                          slots={{
                            openPickerButton: () => null,
                          }}
                          shouldDisableDate={disableDates}
                          disablePast={true}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
                {!appointment.date && date ? (
                  <p>
                    <strong>Please select a timeslot below</strong>
                  </p>
                ) : null}
                {date ? (
                  <Box>
                    <Box sx={{ mb: 2 }}>
                      <strong>{convertAllDates(date, 'PPPP')}</strong>
                    </Box>
                    <Fade in={true} timeout={2000}>
                      <RadioGroup>
                        <Grid container spacing={2}>
                          {date &&
                            appointmentsExist(date) &&
                            timeSlots[date.getFullYear()][date.getMonth()][date.getDate()]
                              .sort((a: any, b: any) => a.startHour - b.startHour || a.startMin - b.startMin)
                              .map((value: any, index: number) => {
                                return (
                                  <Grid item xs={12} sm={6} xl={4}>
                                    <Paper
                                      elevation={12}
                                      sx={{
                                        p: 2,
                                        height: '100%',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        backgroundColor:
                                          appointment.date &&
                                          appointment.date.getHours() === value.startHour &&
                                          appointment.date.getMinutes() === value.startMin
                                            ? '#23c323'
                                            : '#fff',
                                        color:
                                          appointment.date &&
                                          appointment.date.getHours() === value.startHour &&
                                          appointment.date.getMinutes() === value.startMin
                                            ? '#fff'
                                            : '#000',
                                        opacity: value.numAvailable ? 1 : 0.3,
                                      }}
                                      onClick={() => {
                                        if (value.numAvailable) {
                                          selectTime(
                                            new Date(
                                              date.getFullYear(),
                                              date.getMonth(),
                                              date.getDate(),
                                              value.startHour,
                                              value.startMin
                                            ).toISOString()
                                          );
                                        }
                                      }}
                                    >
                                      <FormControlLabel
                                        value={`${convertAllDates(value.dayslot, 'PPPP')} - ${value.timeslot}`}
                                        control={<Radio />}
                                        label={`${value.timeslot}`}
                                      />
                                      {!value.numAvailable ? (
                                        <Box>
                                          <em>No Slots Available</em>
                                        </Box>
                                      ) : null}
                                    </Paper>
                                  </Grid>
                                );
                              })}
                        </Grid>
                      </RadioGroup>
                    </Fade>
                  </Box>
                ) : null}
              </>
            )}
            {!/[0-9]/.test(schedule.locations[0]) || schedule.hideLocation ? null : schedule.locations.length > 1 ? (
              <FormControl>
                <Typography variant="h3">
                  <strong>Select Location</strong>
                </Typography>
                <RadioGroup
                  sx={{ mt: 0.5 }}
                  aria-labelledby="radio-label"
                  name="address-radio"
                  value={appointment.location}
                  onChange={(e) => dispatchAppointment({ type: 'SET_LOCATION', payload: { value: e.target.value } })}
                >
                  <Grid container spacing={2}>
                    {schedule.locations.map((location: string, index: number) => {
                      return (
                        <Grid item xs={12} sm={4}>
                          <FormControlLabel value={location} control={<Radio />} label={location} key={index} sx={{ mr: 0.5 }} />
                          <a
                            style={{ textDecoration: 'none', color: '#000' }}
                            target="_blank"
                            rel="noreferrer"
                            href={`https://www.google.com/maps?q=${schedule.locations[0]}`}
                          >
                            <LocationOnIcon sx={{ color: 'red', fontSize: '16px', pt: 0.5 }} />
                          </a>
                        </Grid>
                      );
                    })}
                  </Grid>
                </RadioGroup>
              </FormControl>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Box>
                  <strong>Location:</strong>{' '}
                  <a
                    style={{ textDecoration: 'none', color: '#000' }}
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps?q=${schedule.locations[0]}`}
                  >
                    <strong>
                      {schedule.locations[0]} <LocationOnIcon sx={{ color: 'red', fontSize: '16px' }} />
                    </strong>
                  </a>
                </Box>
              </Box>
            )}
            {appointment.date ? (
              <Fade in={true} timeout={2000}>
                <Box>
                  <PurchaserForm schedule={schedule} project={project} user={user} registrant={registrant} setRegistrant={setRegistrant} />
                </Box>
              </Fade>
            ) : null}
            {appointment.date && appointment.location && validateEmail(appointment.purchaserInfo.email) ? (
              <FlexEnd sx={{ mt: 2 }}>
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
              </FlexEnd>
            ) : null}
          </form>
        </Grid>
        {appointment.location && !appointment.location.includes('http') ? (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              '@media (max-width: 600px)': {
                height: 300,
                width: '100%',
              },
              '@media (max-width: 1300px)': {
                width: 300,
              },
              height: 500,
              width: '100%',
            }}
          >
            <APIProvider apiKey={process.env.REACT_APP_REACT_MAPS!}>
              <Geocoding />
              {position && position.lat && position.lng ? (
                <Map defaultCenter={position} defaultZoom={15} mapId="DEMO_MAP_ID">
                  <AdvancedMarker position={position} />
                </Map>
              ) : null}
            </APIProvider>
          </Grid>
        ) : null}
      </Grid>
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
            height: '60px',
            '@media (max-width: 600px)': {
              flexDirection: 'column-reverse',
              mt: 10,
              width: '100%',
            },
          }}
        >
          {project.developerLogoGetUrl ? (
            <Box
              sx={{
                height: '100%',
                alignContent: 'center',
                mr: 3,
                mt: 2,
                '@media (max-width: 600px)': {
                  mr: 0,
                  width: '100%',
                },
              }}
            >
              <a
                style={{ textDecoration: 'none', color: '#000' }}
                target="_blank"
                rel="noreferrer"
                href={`https://${project.portal.officialSite}`}
              >
                <img src={project.developerLogoGetUrl!} style={{ maxWidth: '100%', objectFit: 'cover', height: '100%' }} alt="Logo" />
              </a>
            </Box>
          ) : null}
          {project.logoGetUrl ? (
            <Box
              sx={{
                height: '100%',
                alignContent: 'center',
                mr: 3,
                mt: 2,
                '@media (max-width: 600px)': {
                  mr: 0,
                  width: '100%',
                },
              }}
            >
              <a
                style={{ textDecoration: 'none', color: '#000' }}
                target="_blank"
                rel="noreferrer"
                href={`https://${project.portal.officialSite}`}
              >
                <img src={project.logoGetUrl!} style={{ maxWidth: '100%', objectFit: 'cover', height: '100%' }} alt="Logo" />
              </a>
            </Box>
          ) : null}
          {project.developerLogoGetUrl ? (
            <Box sx={{ height: '100%', alignContent: 'center', mt: 2 }}>
              <a style={{ textDecoration: 'none', color: '#000' }} target="_blank" rel="noreferrer" href={`https://rdsbrokerage.com`}>
                <img
                  src={'https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png'}
                  style={{ maxWidth: '100%', objectFit: 'cover', height: '100%' }}
                  alt="Logo"
                />
              </a>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

interface IPosition {
  lat: number;
  lng: number;
}

interface ChildProps {
  project: IProject;
  user: IUser;
  schedule: any;
  scheduleId?: string;
  timeSlots: any;
  submitAppointment: any;
  registrant: boolean | null;
  setRegistrant: Dispatch<SetStateAction<boolean | null>>;
}

export default Main;
